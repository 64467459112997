import { FC } from 'react';
import { Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import Carrousel from './app/components/global/Carrousel';
import Mission from './app/components/sections/Mission';
import About from './app/components/sections/About';
import Offer from './app/components/sections/Offer';
import Method from './app/components/sections/Method';
import Pricing from './app/components/sections/Pricing';
import Contact from './app/components/sections/Contact';
import Layout from './app/components/routes/Layout';
import Footer from './app/components/footer/Footer';
import Team from './app/components/sections/Team';

export const theme = createTheme({
  palette: {
    background: {
      default: '#0d152d',
    },
  },
});

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <header style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <nav>
          <Layout />
        </nav>
      </header>
      <section id="top">
        <Carrousel />
      </section>
      <section>
        <Mission />
      </section>
      <Container>
        <section id="about">
          <About />
        </section>
        <section id="offer">
          <Offer />
        </section>
        <section id="method">
          <Method />
        </section>
        <section id="pricing">
          <Pricing />
        </section>
        <section id="team">
          <Team />
        </section>
        <section id="contact">
          <Contact />
        </section>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
