import React from 'react';
import { Button, ListItemText } from '@mui/material';
import { StyledLink, ResponsiveMenuItem } from '../style';
import { useAppDispatch } from '../../../../hooks';
import { closeMobileMenu } from './menuSlice';
import useTranslations from '../../../global/translation/hooks/translation';
import { scrollFn } from '../helper';

interface Props {}

const MobileMenuLinks = (props: Props) => {
  const { currentLanguage, translations } = useTranslations();
  const dispatch = useAppDispatch();

  const handleCloseNavMenu = () => {
    dispatch(closeMobileMenu());
  };
  return (
    <>
      <Button fullWidth onClick={() => scrollFn('about')}>
        <ResponsiveMenuItem onClick={handleCloseNavMenu}>
          <ListItemText>{translations.menuItems.aboutUs[currentLanguage]}</ListItemText>
        </ResponsiveMenuItem>
      </Button>
      <Button fullWidth onClick={() => scrollFn('offer')}>
        <ResponsiveMenuItem onClick={handleCloseNavMenu}>
          <ListItemText>{translations.menuItems.offer[currentLanguage]}</ListItemText>
        </ResponsiveMenuItem>
      </Button>
      <Button fullWidth onClick={() => scrollFn('method')}>
        <ResponsiveMenuItem onClick={handleCloseNavMenu}>
          <ListItemText>{translations.menuItems.method[currentLanguage]}</ListItemText>
        </ResponsiveMenuItem>
      </Button>
      <Button fullWidth onClick={() => scrollFn('pricing')}>
        <ResponsiveMenuItem onClick={handleCloseNavMenu}>
          <ListItemText>{translations.menuItems.pricing[currentLanguage]}</ListItemText>
        </ResponsiveMenuItem>
      </Button>
      <Button fullWidth onClick={() => scrollFn('team')}>
        <ResponsiveMenuItem onClick={handleCloseNavMenu}>
          <ListItemText>{translations.menuItems.ourTeam[currentLanguage]}</ListItemText>
        </ResponsiveMenuItem>
      </Button>
      <Button fullWidth onClick={() => scrollFn('contact')}>
        <ResponsiveMenuItem onClick={handleCloseNavMenu}>
          <ListItemText>{translations.menuItems.contact[currentLanguage]}</ListItemText>
        </ResponsiveMenuItem>
      </Button>
    </>
  );
};

export default MobileMenuLinks;
