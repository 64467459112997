import { FC } from 'react';
import Grid from '@mui/material/Grid';
import offeerImg from '../../media/offer.png';
import girlsImg from '../../media/girls.png';
import { Divider, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import useTranslations from '../global/translation/hooks/translation';
import Paragraphs from '../global/Paragraphs';

const Offer: FC = () => {
  const { currentLanguage, translations } = useTranslations();
  const theme = useTheme();
  const xsViewPort = useMediaQuery(theme.breakpoints.down('md'));
  const offerTextTop = translations.label.offerTextOne[currentLanguage];
  const offerTextMid = translations.label.offerTextTwo[currentLanguage];
  const offerTextBot = translations.label.offerTextThree[currentLanguage];

  const processText = (text: string) => {
    const paragraphs = text.split('\n\n');
    // Split the text by double newlines to handle paragraphs

    // Process each paragraph
    return paragraphs
      .flatMap((paragraph) => {
        // Check if the paragraph contains list items starting with '- '
        if (paragraph.includes('- ')) {
          // Split paragraph into lines
          return paragraph.split('\n');
        }
        // If no list items, return the paragraph as-is
        return [paragraph];
      })
      .filter((line) => line.trim() !== ''); // Remove any empty lines
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} m={2}>
        <Typography variant="h4" fontWeight={300} color={'#FFF'} textAlign="center">
          {translations.menuItems.offer[currentLanguage]}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={12} sx={{ p: 2, m: 1.5, bgcolor: '#213570' }}>
          <Typography variant="subtitle2" fontWeight={300} color={'#FFF'} textAlign="justify">
            <Paragraphs paragraphs={processText(offerTextTop)} />
          </Typography>
        </Paper>
      </Grid>
      {!xsViewPort && (
        <Grid item xs={12} md={6}>
          <div style={{ position: 'relative', paddingTop: '55%', margin: '16px' }}>
            <img
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
              src={offeerImg}
              alt="Carrousel Image"
            />
          </div>
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <div style={{ position: 'relative', paddingTop: '55%', margin: '16px' }}>
          <img style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }} src={girlsImg} alt="Carrousel Image" />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={12} sx={{ p: 2, m: 1.5, bgcolor: '#213570' }}>
          <Typography variant="subtitle2" fontWeight={300} color={'#FFF'} textAlign="justify">
            <Paragraphs paragraphs={processText(offerTextMid)} />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} p={2}>
        <Divider sx={{ border: '.5px solid #ebebeb4f', borderRadius: 10 }} orientation="horizontal" />
      </Grid>
      {/* <Grid item xs={12} md={12}>
        <Paper elevation={12} sx={{ p: 2, m: 1.5, bgcolor: '#213570' }}>
          <Typography variant="subtitle2" fontWeight={300} color={'#FFF'} textAlign="justify">
            <Paragraphs paragraphs={processText(offerTextBot)} />
          </Typography>
        </Paper>
      </Grid> */}
    </Grid>
  );
};

export default Offer;
