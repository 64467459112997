import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';

export interface MenuSliceStateInterface {
  anchorElNavSlice: boolean | null;
}

const initialState: MenuSliceStateInterface = {
  anchorElNavSlice: null,
};

export const menuSlice = createSlice({
  name: 'menuSlice',
  initialState,
  reducers: {
    openMobileMenu: (state) => {
      state.anchorElNavSlice = true;
    },
    closeMobileMenu: (state) => {
      state.anchorElNavSlice = null;
    },
  },
});

export const { openMobileMenu, closeMobileMenu } = menuSlice.actions;
export const selectMobileMenuSliceState = (state: RootState) => state.mobileMenuSliceState;
export default menuSlice.reducer;
