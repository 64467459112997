// translations/buttons.ts
const labelTranslations: Record<string, Record<string, string>> = {
  heroText: {
    en: 'Urban Equestrian is a professional equestrian business offering basic to advanced training, jump training, horse breaking, horse transportation, and pension services. Their goal is to provide top-notch care for both passionate equestrians and their horses.',
    pl: 'Urban Equestrian oferuję treining jeźdca od nauki jazdy po wszechstronny zaawansowany trening (skoki ujeżdżenie), treining konia zajeźdźanie, korekta, treinning wszechstronny. Rownież transport koni, pensjonat. Celem jest zapewnienie opieki na najwyższym poziomie zarówno dla pasjonatów jeździectwa jak i ich koni.',
  },
  aboutUsText: {
    en: `
    Urban Equestrian is a family stable where we combine work with passion. Our main trainer is Barbara Urban-Jimenez, who gained experience 
    in working with horses during her studies.
    After defending her master's degree in Hippology and Horsemanship, she went to the United States, where she worked as a manager of 
    a sports stable. She was responsible not only for organizing the work of the entire center, but also - and above all - 
    for training and preparing horses for safe work under a rider.\n\n
    After returning to Poland, she worked as a training rider at the Służewiec Racecourse in Warsaw. Then she went to the Netherlands, 
    where she gained experience in one of the most famous dressage stables in Europe. At Van olst Horses, she worked with Grand Prix horses. 
    Then she moved to Germany, where she worked in a sports stable with a jumping profile as a house rider and prepared horses for starts 
    from break-in to the Grand Prix class.\n\n
    Experience gained in Poland and abroad as an instructor allows for comprehensive training of the rider also in English.\n\n
    Basia is a licensed PZJ trainer and has current 3rd class judging qualifications in dressage, show jumping and eventing. 
    In her work, she pays particular attention to continuous training and acquiring knowledge about horses and equestrianism 
    in general in line with the latest research and discoveries.\n\n
    In our stable, we understand that the well-being of horses is the best investment in a long-term and good relationship with them. 
    By taking care of horses, we create conditions for achieving full harmony in equestrianism, where both the rider and the horse derive 
    joy and satisfaction from working together.
    `,
    pl: `Urban Equestrian to stajnia rodzinna, w której pracę łączymy z pasją. Naszym głównym szkoleniowcem jest Barbara Urban-Jimenez, 
    która doświadczenie w pracy z końmi zdobywała już podczas studiów.
    Po obronieniu tytułu magistra inżyniera na kierunku Hipologia i Jeździectwo wyjechała do Stanów Zjednoczonych, gdzie pracowała na 
    stanowisku managera stajni sportowej. Zajmowała się nie tylko organizacja pracy całego ośrodka, ale także - i przede wszystkim -  
    treningiem i przygotowaniem koni do bezpiecznej pracy pod jeźdźcem.\n\n
    Po powrocie do kraju, jako jeździec treningowy, pracowała na Torze Wyścigowym Służewiec w Warszawie. Następnie wyjechała do Holandii, 
    gdzie zdobywała doświadczenie w jednej z najsłynniejszych stajni ujeżdżeniowych w Europie. W Van olst Horses pracowała z końmi klasy Grand Prix.
    Następnie przeniosła się do Niemiec, gdzie w stajni sportowej o profilu skokowym pracowała jako jeździec domowy i przygotowywała konie 
    do startów od zajeżdżenie do klasy Grand Prix włącznie.\n\n
    Zdobyte w kraju i za granicą doświadczenie w pracy instruktora pozwala na wszechstronny trening jeźdźca również w języku angielskim.\n\n 
    Basia jest licencjonowanym szkoleniowcem PZJ oraz posiada aktualne uprawnienia sędziowskie 3 klasy w ujeżdżeniu, skokach przez 
    przeszkody i WKKW. W swojej pracy szczególnie zwraca uwagę na ciągłe doszkalanie się i zdobywanie zgodnej z najnowszymi badaniami i 
    odkryciami wiedzy na temat koni i jeździectwa w ogóle.\n\n
    W naszej stajni rozumiemy, że dobrostan koni to najlepsza inwestycja w długotrwałą i dobrą relację z nimi. Dbając o konie, tworzymy 
    warunki do osiągnięcia pełnej harmonii w jeździectwie, gdzie zarówno jeździec, jak i koń czerpią radość i satysfakcję ze wspólnej pracy.
    `,
  },
  offerTextOne: {
    en: `Comprehensive work with horses:\n\n
        \n- Breaking in and comprehensive preparation of young horses for riding
        \n- Correction of difficult horses
        \n- Work with horses after transitions
        \n- Work from the ground
    `,
    pl: `Kompleksowa praca z końmi:\n\n
        \n- Zajeżdżanie i wszechstronne przygotowanie młodych koni do jazdy
        \n- Korekta koni trudnych
        \n- Praca z końmi po przejściach
        \n- Praca z ziemi, longowanie, zajeżdżanie    
`,
  },
  offerTextTwo: {
    en: `Our approach to horses and horse riding is holistic. We take care of all aspects of the horse's life - physical, mental and emotional. We attach great importance to teaching the basics. We want cooperation at the horse-rider level to take place in understanding the horse's biomechanics, without the use of violence and to be based primarily on partnership.\n\n
        \n- Horse riding lessons for children and adults
        \n- Rider training at various stages of training
        \n- A variety of trails
    `,
    pl: `Nasze podejście do koni i jeździectwa jest holistyczne. Dbamy o wszystkie aspekty życia wierzchowca – te fizyczne, psychiczne i emocjonalne. Przykładamy dużą wagę do nauki podstaw. Zależy nam, aby współpraca na poziomie koń – jeździec odbywała się w zrozumieniu biomechaniki konia, bez użycia przemocy i była oparta przede wszystkim na partnerstwie.\n\n
        \n- Nauka jazdy konnej dla dzieci i dorosłych
        \n- Trening jeźdźca na różnym etapie wyszkolenia
        \n- Wyjazdy w teren
    `,
  },
  offerTextThree: {
    en: 'Hippotherapy\n\nRehabilitation with the help of a horse, affecting psychomotor, psychomotor, sensory, mental and social. It aims to improve the physical and mental fitness of the patient. Conducted with the consent of a doctor, in the absence of contraindications.',
    pl: 'Hipoterapia\n\nRehabilitacja przy pomocy konia, oddziaływująca jednocześnie psychomotorycznie, psychoruchowo, sensorycznie, psychicznie i społecznie. Ma na celu poprawienie sprawności fizycznej oraz psychicznej pacjenta. Prowadzona za zgodą lekarza, przy braku przeciwwskazań!',
  },
  methodText: {
    pl: `Nasze metody treningowe opieramy głównie na zrozumieniu psychiki koni, wychodząc z założenia, że bez podstaw teoretycznych nie ma dobrej praktyki. Później -  to już kwestia wyczucia i doświadczenia… Do każdego konia podchodzimy indywidualnie, czerpiąc zarówno ze szkoły klasycznej jak i naturalnej. Staramy się odrzucić schemat dominacji, a w wierzchowcu szukamy przede wszystkim partnera, który nam ufa i dzięki temu jest chętny do współpracy.
    \n\nIm większa wiedza na temat psychiki oraz biomechaniki ciała konia – tym lepsze efekty treningowe.`,
    en: `Our training methods are based mainly on understanding the psyche of horses, assuming that there is no good practice without theoretical foundations. Later - it is a matter of intuition and experience... We approach each horse individually, drawing from both the classical and natural schools. We try to reject the dominance scheme, and in the mount we look primarily for a partner who trusts us and is therefore willing to cooperate.
    \n\nThe greater the knowledge about the psyche and biomechanics of the horse's body - the better the training effects.`,
  },
  name: {
    en: 'Name',
    pl: 'Imie',
  },
  date: {
    en: 'Date',
    pl: 'Data',
  },
  from: {
    en: 'From',
    pl: 'Od',
  },
  to: {
    en: 'To',
    pl: 'Do',
  },
  status: {
    en: 'Status',
    pl: 'Status',
  },
  available: {
    en: 'Available',
    pl: 'Dostępny',
  },
  break: {
    en: 'Break',
    pl: 'Przerwa',
  },
  blocked: {
    en: 'Blocked',
    pl: 'Zablokowany',
  },
  booked: {
    en: 'Booked',
    pl: 'Zarezerwowane',
  },
  confirmed: {
    en: 'Confirmed',
    pl: 'Potwierdzony',
  },
  done: {
    en: 'Done',
    pl: 'Dokonany',
  },
  clientAbsent: {
    en: 'Client Absent',
    pl: 'Klient nieobecny',
  },
  cancelled: {
    en: 'Cancelled',
    pl: 'Odwołany',
  },
  hr: {
    en: 'hr',
    pl: 'gdz',
  },
  min: {
    en: 'min',
    pl: 'm',
  },
  yes: {
    en: 'yes',
    pl: 'tak',
  },
  no: {
    en: 'no',
    pl: 'nie',
  },
  logout: {
    en: 'Logout',
    pl: 'Wyloguj',
  },
  weekday: {
    en: 'Weekday',
    pl: 'Dzień powszedni',
  },
  serviceName: {
    en: 'Name',
    pl: 'Nazwa',
  },
  translations: {
    en: 'Translations',
    pl: 'Tłumaczenia',
  },
  selectTranslations: {
    en: 'Select translations',
    pl: 'Wybierz tłumaczenia',
  },
  enabled: {
    en: 'Enabled',
    pl: 'Włączony',
  },
  disabled: {
    en: 'Disabled',
    pl: 'Wyłączony',
  },
  serviceNameEN: {
    en: 'Name (EN)',
    pl: 'Nazwa (EN)',
  },
  serviceNameES: {
    en: 'Name (ES)',
    pl: 'Nazwa (ES)',
  },
  serviceNamePL: {
    en: 'Name (PL)',
    pl: 'Nazwa (PL)',
  },
  serviceNameUK: {
    en: 'Name (UK)',
    pl: 'Nazwa (UK)',
  },
  service: {
    en: 'Service',
    pl: 'Usługa',
  },
  duration: {
    en: 'Duration',
    pl: 'Czas trwania',
  },
  price: {
    en: 'Price',
    pl: 'Cena',
  },
  actions: {
    en: 'Actions',
    pl: 'Działania',
  },
  action: {
    en: 'Action',
    pl: 'Działanie',
  },
  admin: {
    en: 'Admin',
    pl: 'Admin',
  },
  shortInfo: {
    en: 'Short Information',
    pl: 'Krótka informacja',
  },
  shortInfoEN: {
    en: 'Short Information (EN)',
    pl: 'Krótka informacja (EN)',
  },
  shortInfoES: {
    en: 'Short Information (ES)',
    pl: 'Krótka informacja (ES)',
  },
  shortInfoPL: {
    en: 'Short Information (PL)',
    pl: 'Krótka informacja (PL)',
  },
  shortInfoUK: {
    en: 'Short Information (UK)',
    pl: 'Krótka informacja (UK)',
  },
  description: {
    en: 'Description',
    pl: 'Opis',
  },
  finish: {
    en: 'Finish',
    pl: 'Zakończ',
  },
  resource: {
    en: 'Resource',
    pl: 'Zasób',
  },
  type: {
    en: 'Type',
    pl: 'Typ',
  },
  staffFor: {
    en: 'Staff for',
    pl: 'Personel do',
  },
  business: { en: 'Business', pl: 'Biznes' },
  email: { en: 'Email', pl: 'E-mail' },
  phone: { en: 'Phone', pl: 'Telefon' },
  street: { en: 'Street', pl: 'Ulica' },
  postcode: { en: 'Postcode', pl: 'Kod pocztowy' },
  city: { en: 'City', pl: 'Miasto' },
  country: { en: 'Country', pl: 'Kraj' },
  closed: { en: 'Closed', pl: 'Zamknięte' },
  servicesFor: {
    en: 'Services for',
    pl: 'Usługi dla',
  },
  newPassword: {
    en: 'New password',
    pl: 'Nowe hasło',
  },
  password: {
    en: 'Password',
    pl: 'Hasło',
  },
  companyName: {
    en: 'Company Name',
    pl: 'Naszwa firma',
  },
  companyNip: {
    en: 'Company NIP',
    pl: 'NIP firmy',
  },
  createAccount: {
    en: 'Create an account',
    pl: 'Zarejestruj konto',
  },
  pleaseActivateAccount: {
    en: 'Activate your account!',
    pl: 'Proszę aktywować konto!',
  },
  pleaseCreateAccount: {
    en: 'Please create an account',
    pl: 'Proszę założyć konto',
  },
  alreadyHaveAccount: {
    en: 'Already have an account?',
    pl: 'Masz już konto?',
  },
  bySigning: {
    en: 'By signing up you agree to our ',
    pl: 'Rejestrując się wyrażasz zgodę na nasze',
  },
  termsOfService: {
    en: 'Terms of Service',
    pl: 'Warunki Usługi',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    pl: 'Polityka prywatności',
  },
  rightsReserved: {
    en: 'All rights reserved',
    pl: 'Prawa zastrzeżone',
  },
  hasTranslations: {
    en: 'Translations',
    pl: 'Tłumaczenia',
  },
  directContact: {
    en: 'Direct Contact',
    pl: 'Kontakt bezpośredni',
  },
  // Add more translations as needed
};

export default labelTranslations;
