// translations/buttons.ts
const daysOfWeekTranslations: Record<string, Record<string, string>> = {
  monday: {
    en: 'Monday',
    pl: 'Poniedziałek',
  },
  tuesday: {
    en: 'Tuesday',
    pl: 'Wtorek',
  },
  wednesday: {
    en: 'Wednesday',
    pl: 'Środa',
  },
  thursday: {
    en: 'Thursday',
    pl: 'Czwartek',
  },
  friday: {
    en: 'Friday',
    pl: 'Piątek',
  },
  saturday: {
    en: 'Saturday',
    pl: 'Sobota',
  },
  sunday: {
    en: 'Sunday',
    pl: 'Niedziela',
  },
};

export default daysOfWeekTranslations;
