import React, { useState, useEffect } from 'react';
import img1 from '../../media/coristo.png';
import img2 from '../../media/b&b.png';
import img3 from '../../media/basiaCarrousel.png';
import img4 from '../../media/promyk.png';
import img5 from '../../media/ponchoCarrousel.png';
import img6 from '../../media/p&b.png';
import { Grid, IconButton, Fade, Typography, useMediaQuery } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { theme } from '../../../App';

type Props = {};

const Carrousel = (props: Props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [switchedImage, setSwitchedImage] = useState(true);
  const imgs = [img1, img2, img3, img4, img5, img6];

  const transitionFunction = (fn: Function) => {
    setSwitchedImage(false);
    setTimeout(() => {
      setSwitchedImage(true);
      fn();
    }, 1000);
  };

  const nextImage = () => {
    transitionFunction(() => setCurrentImage((prevImage) => (prevImage + 1) % imgs.length));
  };

  const previousImage = () => {
    transitionFunction(() => setCurrentImage((prevImage) => (prevImage - 1 + imgs.length) % imgs.length));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, [currentImage]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item md={1} justifyContent="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
        <IconButton color="primary" sx={{ marginRight: '10px', '&.Mui-disabled': { color: '#1976d270' } }} onClick={previousImage} disabled={!switchedImage}>
          <ArrowBackIos />
        </IconButton>
      </Grid>
      <Fade in={switchedImage} timeout={1000}>
        <Grid item xs={12} md={10}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <div style={{ position: 'relative', paddingTop: '45%' }}>
                <img
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
                  src={imgs[currentImage]}
                  alt="Carrousel Image"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h6"
                color={'#FFF'}
                textTransform={'uppercase'}
                fontWeight={300}
                sx={{ textAlign: { xs: 'center', md: 'left' }, borderBottom: '.1rem solid #FFF' }}
              >
                {
                  {
                    0: 'Coristo',
                    1: 'Bajka & Balou',
                    2: 'Barbara Jimenez',
                    3: 'Promyk',
                    4: 'Alfonso Jimenez',
                    5: 'Pasja & Frika',
                  }[currentImage]
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
      <Grid item md={1} justifyContent="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
        <IconButton color="primary" sx={{ marginLeft: '10px', '&.Mui-disabled': { color: '#1976d270' } }} onClick={nextImage} disabled={!switchedImage}>
          <ArrowForwardIos />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Carrousel;
