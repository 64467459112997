// translationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import translations from '.';

interface TranslationState {
  currentLanguage: string;
  translations: typeof translations;
}

const initialState: TranslationState = {
  currentLanguage: 'pl', // Set the initial language here
  translations,
};

const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.currentLanguage = action.payload;
    },
    setTranslations: (state, action: PayloadAction<typeof translations>) => {
      state.translations = action.payload;
    },
  },
});

export const { setLanguage, setTranslations } = translationSlice.actions;
export default translationSlice.reducer;
