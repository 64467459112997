import { FC } from 'react';
import Grid from '@mui/material/Grid';
import usImg from '../../media/aboutus.png';
import { Paper, Typography } from '@mui/material';
import useTranslations from '../global/translation/hooks/translation';
import React from 'react';
import Paragraphs from '../global/Paragraphs';

const About: FC = () => {
  const { currentLanguage, translations } = useTranslations();
  const text = translations.label.aboutUsText[currentLanguage];
  const paragraphs = text.split('\n\n');

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} m={2}>
        <Typography variant="h4" fontWeight={300} color={'#FFF'} textAlign="center">
          {translations.menuItems.aboutUs[currentLanguage]}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ position: 'relative', paddingTop: '35%', margin: '16px' }}>
          <img style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }} src={usImg} alt="Carrousel Image" />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={12} sx={{ p: 2, m: 2, bgcolor: '#ebebeb4f' }}>
          <React.Fragment>
            <Paragraphs paragraphs={paragraphs} />
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default About;
