// translations/index.ts
import buttonTranslations from './components/buttons';
import menuTranslations from './components/menuItems';
import otherTranslations from './components/other';
import typographyTranslations from './components/typography';
import labelTranslations from './components/labels';
import loadingTranslations from './components/loading';
import daysOfWeekTranslations from './components/daysOfWeek';

const translations = {
  buttons: buttonTranslations,
  menuItems: menuTranslations,
  other: otherTranslations,
  typography: typographyTranslations,
  label: labelTranslations,
  loading: loadingTranslations,
  dow: daysOfWeekTranslations,
  // Add more categories as needed
};

export default translations;

export type TranslationCategory = keyof typeof translations;
export type TranslationKey<T extends TranslationCategory> = keyof (typeof translations)[T];
