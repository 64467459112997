import { FC } from 'react';
import Grid from '@mui/material/Grid';
import contactImg from '../../media/contact.png';
import { Button, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import useTranslations from '../global/translation/hooks/translation';
import { LocationOn, Phone, Email } from '@mui/icons-material';

const Contact: FC = () => {
  const { currentLanguage, translations } = useTranslations();
  return (
    <Grid container justifyContent="center" alignItems="center" pb={6}>
      <Grid item xs={12} m={2}>
        <Typography variant="h4" fontWeight={300} color={'#FFF'} textAlign="center">
          {translations.menuItems.contact[currentLanguage]}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ position: 'relative', paddingTop: '65%', margin: '16px' }}>
          <img
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
            src={contactImg}
            alt="Carrousel Image"
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={12} sx={{ p: 2, m: 2, bgcolor: '#ebebeb4f' }}>
          <Button
            variant="text"
            fullWidth
            onClick={() => {
              window.open(
                'https://www.google.com/maps/dir//Urban+Equestrian,+Koszary/@51.1245855,21.1794487,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47186ddde34b675b:0xed82cb2b23b6e247!2m2!1d21.2494807!2d51.1245984'
              );
            }}
            startIcon={<LocationOn sx={{ color: '#FFF' }} />}
            sx={{ color: '#FFF', justifyContent: 'left', textTransform: 'none', fontWeight: 300 }}
          >
            Koszary 8b, 27-100, Iłza
          </Button>
          <Button
            variant="text"
            fullWidth
            onClick={() => window.open('tel:729883891', '_self')}
            startIcon={<Phone sx={{ color: '#FFF' }} />}
            sx={{ color: '#FFF', my: 1, justifyContent: 'left', textTransform: 'none', fontWeight: 300 }}
          >
            (+48) 729 883 891
          </Button>
          <Button
            variant="text"
            fullWidth
            onClick={() => {
              window.open('mailto:basia0urban@gmail.com');
            }}
            startIcon={<Email sx={{ color: '#FFF' }} />}
            sx={{ color: '#FFF', justifyContent: 'left', textTransform: 'none', fontWeight: 300 }}
          >
            basia0urban@gmail.com
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Contact;
