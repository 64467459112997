import { Grid, Typography } from '@mui/material';
import React from 'react';
import useTranslations from '../global/translation/hooks/translation';

type Props = {};

const Mission = (props: Props) => {
  const { currentLanguage, translations } = useTranslations();
  return (
    <Grid container color="#FFF" sx={{ bgcolor: '#010921' }} justifyContent="center">
      <Grid item py={12} px={'4rem'}>
        <Typography variant="h6" fontWeight={300} textAlign="center">
          {translations.label.heroText[currentLanguage]}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Mission;
