import { useSelector } from 'react-redux';
import { RootState } from '../../../../store'; // Update the relative path to the correct location of the store module or type declarations

const useTranslations = () => {
  const currentLanguage = useSelector((state: RootState) => state.selectTranslation.currentLanguage);
  const translations = useSelector((state: RootState) => state.selectTranslation.translations);

  return { currentLanguage, translations };
};

export default useTranslations;
