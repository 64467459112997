import { Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  paragraphs: string[];
}

const Paragraphs: FC<Props> = ({ paragraphs }) => {
  return (
    <div>
      {paragraphs.map((para, index) => (
        <Typography
          variant="subtitle2"
          fontWeight={300}
          color="#FFF"
          textAlign="justify"
          key={index}
          paragraph // This adds a margin bottom, creating spacing between paragraphs
          style={{
            marginBottom: index !== paragraphs.length - 1 ? '1em' : '0', // Adds margin between paragraphs only
          }}
        >
          {para}
        </Typography>
      ))}
    </div>
  );
};

export default Paragraphs;
