// translations/buttons.ts
const buttonTranslations: Record<string, Record<string, string>> = {
  selectedDate: {
    en: 'Selected date',
    pl: 'Wybrana data',
  },
  selectTranslation: {
    en: 'Select Translation',
    pl: 'Wybierz Tłumaczenie',
  },
  noBookings: {
    en: 'No bookings found.',
    pl: 'Nie znaleziono żadnych rezerwacji.',
  },
  noResources: {
    en: 'No resources available.',
    pl: 'Brak dostępnych zasobów.',
  },
  noTimeslots: {
    en: 'No timeslots available.',
    pl: 'brak dostępnych przedziałów czasowych.',
  },
  noSchedule: {
    en: 'No schedule found.',
    pl: 'Nie znaleziono grafik.',
  },
  noEmployees: {
    en: 'No employees found.',
    pl: 'Nie znaleziono pracowników.',
  },
  adminScheduleMsg: {
    en: 'Start by adding a schedule.',
    pl: 'Zacznij od dodania grafiku.',
  },
  contactAdminMsg: {
    en: 'Please contact your admin.',
    pl: 'Skontaktuj się ze swoim administratorem.',
  },
  businessClosed: {
    en: 'Business is closed.',
    pl: 'Działalność jest zamknięta.',
  },
  addBooking: {
    en: 'Add booking (me)',
    pl: 'Dodaj termin (ja)',
  },
  createBooking: {
    en: 'Create booking',
    pl: 'Dodaj termin',
  },
  createBookingStaff: {
    en: 'Create booking staff',
    pl: 'Dodaj termin personel',
  },
  createBookingResource: {
    en: 'Create booking resource',
    pl: 'Dodaj termin zasób',
  },
  add: {
    en: 'Add',
    pl: 'Dodaj',
  },
  addBookingStaff: {
    en: 'Add booking staff',
    pl: 'Dodaj termin personel',
  },
  addBookingResource: {
    en: 'Add booking resource',
    pl: 'Dodaj termin zasób',
  },
  applyForDay: {
    en: 'Apply for day (me)',
    pl: 'Zastosuj grafik (ja)',
  },
  applyForDayFor: {
    en: 'Apply for day for',
    pl: 'Zastosuj grafik dla',
  },
  applyForDayStaff: {
    en: 'Apply for day staff',
    pl: 'Zastosuj grafik personel',
  },
  applyForDayResource: {
    en: 'Apply for day resource',
    pl: 'Zastosuj grafik zasób',
  },
  editBooking: {
    en: 'Edit booking',
    pl: 'Edytować rezerwację',
  },
  changeBooking: {
    en: 'Change booking',
    pl: 'Zmienić rezerwację',
  },
  updateBooking: {
    en: 'Update booking',
    pl: 'Zaktualizować rezerwację',
  },
  cancelBooking: {
    en: 'Cancel booking',
    pl: 'Anulować rezerwację',
  },
  addSchedule: {
    en: 'Add schedule',
    pl: 'Dodaj grafik',
  },
  createScheduleFor: {
    en: 'Create schedule for',
    pl: 'Utwórz grafik dla',
  },
  cloneSchedule: {
    en: 'Clone schedule',
    pl: 'Klonowania grafik',
  },
  cloneScheduleFor: {
    en: 'Clone schedule for',
    pl: 'Klonowania grafik dla',
  },
  clone: {
    en: 'Clone',
    pl: 'Klonowania',
  },
  cancelSchedule: {
    en: 'Cancel schedule',
    pl: 'Anulować grafik',
  },
  areYouSure: {
    en: 'Are you sure?',
    pl: 'Jesteś pewny?',
  },
  inactive: {
    en: 'Inactive',
    pl: 'Nieaktywny',
  },
  active: {
    en: 'Active',
    pl: 'Aktywny',
  },
  serviceStaff: {
    en: 'Service staff',
    pl: 'Obsługa personelu',
  },
  addService: {
    en: 'Add service',
    pl: 'Dodaj usługę',
  },
  createService: {
    en: 'Create service',
    pl: 'Utwórz usługę',
  },
  editService: {
    en: 'Edit service',
    pl: 'Edytować usługę',
  },
  editTranslations: {
    en: 'Edit translations',
    pl: 'Edytuj tłumaczenia',
  },
  assignResources: {
    en: 'Assign resource(s)',
    pl: 'Przypisz zasób',
  },
  finished: {
    en: 'Finished',
    pl: 'Skończone',
  },
  emptyServicesMsg: {
    en: 'The service list is empty! Start by creating a service.',
    pl: 'Lista usług jest pusta! Zacznij od utworzenia usługi.',
  },
  createResource: {
    en: 'Create resource',
    pl: 'Utwórz zasób',
  },
  emptyResourcesMsg: {
    en: 'The resources list is empty! Start by creating a resource.',
    pl: 'Lista zasoby jest pusta! Zacznij od utworzenia zasobu.',
  },
  resourceCreationMsg: {
    en: 'Done! Now go check the resources.',
    pl: 'Zrobione! A teraz idź sprawdzić zasoby.',
  },
  serviceCreationMsg: {
    en: 'Done! Now go check the services.',
    pl: 'Zrobione! A teraz idź sprawdzić usługi.',
  },
  general: {
    en: 'General',
    pl: 'Ogólny',
  },
  openingHours: {
    en: 'Opening Hours',
    pl: 'Godziny otwarcia',
  },
  checkBusinessInfo: {
    en: 'Check your business information',
    pl: 'Sprawdź informacje o swojej firmie',
  },
  businessDetails: {
    en: 'Business Details',
    pl: 'Szczegóły Biznesowe',
  },
  address: {
    en: 'Address',
    pl: 'Adres',
  },
  configureHours: {
    en: 'Configure the working hours for your business',
    pl: 'Skonfiguruj godziny pracy dla swojej firmy',
  },
  emptyEmployeesMsg: {
    en: 'The employee list is empty!',
    pl: 'Lista pracowników jest pusta!',
  },
  createEmployee: {
    en: 'Create employee',
    pl: 'Utwórz pracownika',
  },
  waitingForConfirmation: {
    en: 'Waiting for confirmation',
    pl: 'Oczekiwanie na potwierdzenie',
  },
  emailSentTo: {
    en: 'Email sent to',
    pl: 'E-mail wysłany do',
  },
  instructionsSent: {
    en: 'Instructions sent to email provided.',
    pl: 'Instrukcje przesłane na podany e-mail.',
  },
  editEmployee: {
    en: 'Edit employee',
    pl: 'Edytuj pracownika',
  },
  deleteEmployee: {
    en: 'Delete employee',
    pl: 'Usuń pracownika',
  },
  deleteEmployeeMsg: {
    en: 'Are you sure you want to delete this employee permanently?',
    pl: 'Czy na pewno chcesz trwale usunąć tego pracownika?',
  },
  irreversibleAction: {
    en: 'This action is irreversible',
    pl: 'To działanie jest nieodwracalne.',
  },
  checkAccInfo: {
    en: 'Check your account information',
    pl: 'Sprawdź informacje o swojej konto',
  },
  accDetails: {
    en: 'Account Details',
    pl: 'Szczegóły Konta',
  },
  bannerTitle: {
    en: 'An organized business',
    pl: 'Zorganizowany biznes',
  },
  bannerSubtitle: {
    en: `will take you places you’ve never imagined before`,
    pl: 'zabierze cię w miejsca, o których nigdy wcześniej nie marzyłeś',
  },
  servicesTitle: {
    en: 'Affordable services for everybody',
    pl: 'Niedrogie usługi dla każdego',
  },
  schedulingSystem: {
    en: 'Scheduling System',
    pl: 'System planowania',
  },
  employeePanel: {
    en: 'Employee Panel',
    pl: 'Panel pracowniczy',
  },
  servicePanel: {
    en: 'Service Panel',
    pl: 'Panel serwisowy',
  },
  reservationPanel: {
    en: 'Reservation Panel',
    pl: 'Panel rezerwacji',
  },
  andMore: {
    en: 'And much more',
    pl: 'I wiele więcej',
  },
  readMore: {
    en: 'Read more',
    pl: 'Czytaj więcej',
  },
  businessTitle: {
    en: 'Business organization for everyone',
    pl: 'Organizacja biznesu dla każdego',
  },
  businessSubtitle: {
    en: 'We consider ourselves as pioneers that firmly believe that all small businesses should have the opportunity to use high quality software to manage their appointments for a fair price.',
    pl: 'Uważamy się za pionierów, którzy mocno wierzą, że wszystkie małe firmy powinny mieć możliwość korzystania z wysokiej jakości oprogramowania do zarządzania swoimi spotkaniami za uczciwą cenę.',
  },
  tailoredDevelopment: {
    en: 'Tailored development',
    pl: 'Rozwój szyty na miarę',
  },
  specialRequests: {
    en: 'Special requests',
    pl: 'Specjalna prosba',
  },
  websiteCreation: {
    en: 'Website creation',
    pl: 'Tworzenie strony internetowej',
  },
  weAdapt: {
    en: 'We adapt to your needs',
    pl: 'Dostosowujemy się do twoich potrzeb',
  },
  contactTitle: {
    en: 'We are glad to meet you',
    pl: 'Cieszymy się, że możemy cię poznać',
  },
  contactSubtitle: {
    en: 'Tell us more in an email',
    pl: 'Powiedz nam więcej w e-mailu',
  },
  send: {
    en: 'Send',
    pl: 'Wysłać',
  },
  companiesTitle: {
    en: 'Companies that trust us',
    pl: 'Firmy, które nam zaufały',
  },
  overview: {
    en: 'Overview',
    pl: 'Przegląd',
  },
  releases: {
    en: 'Releases',
    pl: 'Wydania',
  },
  aboutUs: {
    en: 'About us',
    pl: 'O nas',
  },
  news: {
    en: 'News',
    pl: 'Aktualności',
  },
  tutorials: {
    en: 'Tutorials',
    pl: 'Poradniki',
  },
  comingSoon: {
    en: 'Coming soon',
    pl: 'Będzie wkrótce',
  },
  product: {
    en: 'Product',
    pl: 'Produkt',
  },
  company: {
    en: 'Company',
    pl: 'Firma',
  },
  support: {
    en: 'Support',
    pl: 'Wsparcie',
  },
  legal: {
    en: 'Legal',
    pl: 'Prawny',
  },
  // Add more translations as needed
};

export default buttonTranslations;
