import { FC } from 'react';
import Grid from '@mui/material/Grid';
import usImg from '../../media/aboutus.png';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import useTranslations from '../global/translation/hooks/translation';

const Pricing: FC = () => {
  const { currentLanguage, translations } = useTranslations();
  const exampleVals = {
    price: 'Price',
    cena: 'Cena',
    zajeciaIndiwidulane: 'Zajęcia Indywidulane',
    zajeciaWParze: 'Zajęcia w Parze',
    oneHr: '60min',
    fourtyFive60Min: '45-60min',
    fourtyFiveMin: '45min',
    fiftyMin: '50min',
    halfHour: '30min',
    seventyFiveMin: '75min',
    description: 'Opis',
    training: 'Trening',
    trainingIndywidulane: 'Trening indywidulane',
    trainingWParze: 'Trening w Parze',
    trainingSkokowy: 'Trening skokowy',
    trainingSkokowyIndywidulane: 'Trening skokowy indywidulane',
    trainingNaKoniuPrywatny: 'Trening na koniu prywatny',
    lonza: 'Lonża',
    zajeciaDlaNajmlodszych: 'Zajecia dla najmlodszych',
    naukaLonzowaniaPracyZZiemii: 'Nauka lonżowania / Pracy z ziemii',
    teren: 'Teren',
    trailRide: 'Trail Ride',
    lesson: 'Lesson',
    horseRental: 'Horse Rental',
    serwis: 'Serwis',
    czasTrwania: 'Czas trwania',
    karnety: 'Karnety',
    pensjonat: 'Pensjonat',
    treiningKonia: 'Trening konia',
    otherServices: 'Inne usługi',
    boksWolnyWybieg: 'Boks / wolny wybieg',
    ustalanyIndywidualnie: 'Ustalany indywidualnie',
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} m={2}>
        <Typography variant="h4" fontWeight={300} color={'#FFF'} textAlign="center">
          {translations.menuItems.pricing[currentLanguage]}
        </Typography>
      </Grid>
      <Grid item xs={12} m={2}>
        <Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">{exampleVals.zajeciaIndiwidulane}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.serwis}</TableCell>
                  <TableCell>{exampleVals.czasTrwania}</TableCell>
                  <TableCell>{exampleVals.cena}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{exampleVals.training}</TableCell>
                  <TableCell>{exampleVals.oneHr}</TableCell>
                  <TableCell>120zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.trainingSkokowy}</TableCell>
                  <TableCell>{exampleVals.oneHr}</TableCell>
                  <TableCell>150zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.trainingNaKoniuPrywatny}</TableCell>
                  <TableCell>{exampleVals.fourtyFive60Min}</TableCell>
                  <TableCell>120zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.lonza}</TableCell>
                  <TableCell>{exampleVals.halfHour}</TableCell>
                  <TableCell>80zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.lonza}</TableCell>
                  <TableCell>{exampleVals.fourtyFiveMin}</TableCell>
                  <TableCell>100zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.zajeciaDlaNajmlodszych}</TableCell>
                  <TableCell>{exampleVals.halfHour}</TableCell>
                  <TableCell>80zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.naukaLonzowaniaPracyZZiemii}</TableCell>
                  <TableCell>{exampleVals.fourtyFiveMin}</TableCell>
                  <TableCell>100zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.teren}</TableCell>
                  <TableCell>{exampleVals.seventyFiveMin}</TableCell>
                  <TableCell>150zł</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} m={2}>
        <Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">{exampleVals.zajeciaWParze}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.serwis}</TableCell>
                  <TableCell>{exampleVals.czasTrwania}</TableCell>
                  <TableCell>{exampleVals.cena}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{exampleVals.training}</TableCell>
                  <TableCell>{exampleVals.oneHr}</TableCell>
                  <TableCell>100zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.trainingSkokowy}</TableCell>
                  <TableCell>{exampleVals.oneHr}</TableCell>
                  <TableCell>120zł</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{exampleVals.teren}</TableCell>
                  <TableCell>{exampleVals.seventyFiveMin}</TableCell>
                  <TableCell>120zł</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} m={2}>
        <Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">{exampleVals.karnety}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.serwis}</TableCell>
                  <TableCell>{exampleVals.czasTrwania}</TableCell>
                  <TableCell>{exampleVals.cena}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>5 x {exampleVals.trainingIndywidulane}</TableCell>
                  <TableCell>5 x {exampleVals.oneHr}</TableCell>
                  <TableCell>575zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>5 x {exampleVals.trainingSkokowyIndywidulane}</TableCell>
                  <TableCell>5 x{exampleVals.oneHr}</TableCell>
                  <TableCell>725zł</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>5 x {exampleVals.trainingWParze}</TableCell>
                  <TableCell>5 x {exampleVals.fiftyMin}</TableCell>
                  <TableCell>475zł</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} m={2}>
        <Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">{exampleVals.otherServices}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.serwis}</TableCell>
                  <TableCell>{exampleVals.description}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{exampleVals.pensjonat}</TableCell>
                  <TableCell>{exampleVals.boksWolnyWybieg}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{exampleVals.trainingSkokowyIndywidulane}</TableCell>
                  <TableCell>{exampleVals.ustalanyIndywidualnie}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Pricing;
