// translations/buttons.ts
const otherTranslations: Record<string, Record<string, string>> = {
  welcome: {
    en: 'Welcome',
    pl: 'Witaj',
  },
  goHome: {
    en: 'Go home',
    pl: 'Strony głowny',
  },
  pageNotFound: {
    en: 'Page not found',
    pl: 'Strona nie znaleziona',
  },
  // Add more translations as needed
};

export default otherTranslations;
