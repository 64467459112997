import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import MobileMenuLinks from './MobileMenuLinks';
import MenuIcon from '@mui/icons-material/Menu';
import { OutterResponsiveMenuBox, InnerResponsiveMenuBox, ResponsiveMenu, StyledLink } from '../style';
import whiteLogo from '../../../../media/whiteLogo.png';
import '../logoStyles.css';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { openMobileMenu, closeMobileMenu, selectMobileMenuSliceState } from './menuSlice';
import { scrollFn } from '../helper';

const MobileMenu: FC = () => {
  const dispatch = useAppDispatch();
  const menuSelector = useAppSelector(selectMobileMenuSliceState);
  const { anchorElNavSlice } = menuSelector;

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(openMobileMenu());
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    dispatch(closeMobileMenu());
    setAnchorElNav(null);
  };

  return (
    <OutterResponsiveMenuBox sx={{ display: { xs: 'flex', md: 'none' } }}>
      <InnerResponsiveMenuBox>
        <IconButton size="large" aria-label="hamburger menu" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
          <MenuIcon sx={{ fontSize: '3rem' }} />
        </IconButton>
        <ResponsiveMenu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNavSlice)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <MobileMenuLinks />
        </ResponsiveMenu>
        <StyledLink to="/" onClick={() => scrollFn('top')} style={{ flexGrow: 1, textAlign: 'right' }}>
          <img alt="urban_equestrian" src={whiteLogo} className="whiteLogoStylesMobile" />
        </StyledLink>
      </InnerResponsiveMenuBox>
    </OutterResponsiveMenuBox>
  );
};

export default MobileMenu;
