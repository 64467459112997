import { FC, useEffect } from 'react';
import { Box, Container, Toolbar } from '@mui/material';
import { SecondHeader, StyledLink, MenuBox, MenuButton } from '../style';
import whiteLogo from '../../../../media/whiteLogo.png';
import '../logoStyles.css';
import MobileMenu from '../mobileMenu/MobileMenu';
import { useAppDispatch } from '../../../../hooks';
import useTranslations from '../../../global/translation/hooks/translation';
import { scrollFn } from '../helper';

const SecondAppBar: FC = () => {
  const { currentLanguage, translations } = useTranslations();

  return (
    <>
      <SecondHeader position="static">
        <Container maxWidth="xl" sx={{ margin: 0, alignSelf: 'center' }}>
          <Toolbar disableGutters sx={{ top: 10 }}>
            <MobileMenu />
            <MenuBox sx={{ display: { xs: 'none', md: 'flex' } }}>
              <StyledLink to={'/'} onClick={() => scrollFn('top')}>
                <img alt="urban_equestrian" src={whiteLogo} className="whiteLogoStyles" />
              </StyledLink>
              {/* // Here is the Layout that react router dom v6 requires! */}
              <>
                <MenuButton onClick={() => scrollFn('about')}>{translations.menuItems.aboutUs[currentLanguage]}</MenuButton>
                <MenuButton onClick={() => scrollFn('offer')}>{translations.menuItems.offer[currentLanguage]}</MenuButton>
                <MenuButton onClick={() => scrollFn('method')}>{translations.menuItems.method[currentLanguage]}</MenuButton>
                <MenuButton onClick={() => scrollFn('pricing')}>{translations.menuItems.pricing[currentLanguage]}</MenuButton>
                <MenuButton onClick={() => scrollFn('team')}>{translations.menuItems.ourTeam[currentLanguage]}</MenuButton>
                <MenuButton onClick={() => scrollFn('contact')}>{translations.menuItems.contact[currentLanguage]}</MenuButton>
              </>
            </MenuBox>
          </Toolbar>
        </Container>
      </SecondHeader>
    </>
  );
};

export default SecondAppBar;
